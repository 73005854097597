import React, { useState, useEffect, useContext } from "react"
import styled, { css, ThemeContext } from "styled-components"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { MEDIA_MAX_MEDIUM, MEDIA_MIN_LARGE } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import Link from "../Link"
import CountrySelector from "./CountrySelector"

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  background: ${(p) => p.theme.colors.secondary};
  transition: all 0.3s;
  transform: translate(${(p) => (p.open ? "0, 0" : "-100%, 0")});
  width: 100%;
  max-width: 40rem;
  z-index: 1000;
  overflow-y: auto;

  ${MEDIA_MIN_LARGE} {
    width: 100%;
    max-width: none;
    position: relative;
    flex-wrap: nowrap;
    height: 100%;
    background: none;
    transform: none;
    overflow-y: initial;
  }
`

const MenuClose = styled.div`
  position: absolute;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2rem;
  right: 2.5rem;
  color: ${(p) => p.theme.colors.white};
  font-size: 2rem;

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const Menu = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 7.5rem 0 2rem;
  margin: 0;

  ${MEDIA_MIN_LARGE} {
    padding: 0;
    height: 100%;
  }
`

const MenuItem = styled.li`
  position: relative;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.3rem;
  letter-spacing: 1px;
  transition: border-bottom 0.2s;
  width: 100%;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};

  &:first-of-type {
    border-top: 0.1rem solid ${(p) => p.theme.colors.primaryLight};
  }

  ${MEDIA_MIN_LARGE} {
    width: auto;
    margin: 0 2rem -0.2rem;
    font-size: 1.4rem;
    border-bottom: 0.3rem solid transparent;

    &:first-of-type {
      border-top: none;
    }

    ${(p) =>
      p.hasChildren &&
      css`
        &:hover {
          &:after {
            bottom: -0.2rem;
            left: calc(50% - 1rem);
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-bottom-color: ${(p) => p.theme.colors.primaryOpac};
            border-width: 0.8rem;
          }

          ul {
            display: flex;
          }
        }
      `}
  }

  ${(p) =>
    !p.hasChildren &&
    css`
      &:hover {
        border-bottom: 0.3rem solid ${(p) => p.theme.colors.primary};
      }
    `}
`

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  padding: 2.8rem 4rem 2.4rem;
  color: ${(p) => p.theme.colors.white};

  ${MEDIA_MAX_MEDIUM} {
    i {
      position: absolute;
      right: 4rem;
      transition: transform 0.2s;
      transform: rotate(${(p) => (p.open ? "180deg" : "0deg")});
      font-size: 2.4rem;
    }
  }

  ${MEDIA_MIN_LARGE} {
    padding: 0.2rem 0 0;
    height: 100%;
    color: ${(p) =>
      p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};

    i {
      margin-left: 1rem;
    }
  }
`

const SubMenu = styled.ul`
  list-style: none;
  padding: 0 4rem 0;
  max-height: ${(p) => (p.open ? "30rem" : "0")};
  overflow: hidden;
  min-width: 24rem;
  flex-wrap: wrap;

  ${MEDIA_MIN_LARGE} {
    display: none;
    position: absolute;
    top: calc(100% + 0.1rem);
    left: -2rem;
    background: ${(p) => p.theme.colors.primaryOpac};
    padding: 1.5rem 2rem;
    flex-direction: column;
    max-height: none;
  }
`

const SubMenuItem = styled.li`
  line-height: 4rem;
  font-size: 1.3rem;
  width: 100%;

  &:last-child {
    padding-bottom: 2rem;
  }

  ${MEDIA_MIN_LARGE} {
    width: auto;

    &:last-child {
      padding-bottom: 0;
    }
  }
`

export default ({ menuItems = [], inverted, openMenu, closeMenu, sticky }) => {
  const { t } = useTranslation("translations")
  const { colors } = useContext(ThemeContext)
  const { languageCode } = useContext(BrinkContext)
  const [focusedMenu, setFocusedMenu] = useState(null)
  const [isTouchDevice, setIsTouchDevice] = useState(false)

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.msMaxTouchPoints)
  }, [])

  return (
    <Container open={openMenu}>
      {openMenu && (
        <Helmet>
          <html lang="en" className="disableScroll" />
          <body className="disableScroll" />
        </Helmet>
      )}
      <MenuClose onClick={() => closeMenu()}>
        <i className="fal fa-times"></i>
      </MenuClose>
      <Menu>
        {menuItems.map((menuItem) => {
          const hasChildItems =
            menuItem.childCollections && menuItem.childCollections.length > 0
          return (
            <MenuItem key={menuItem.slug.current} hasChildren={hasChildItems}>
              <MenuLink
                open={menuItem.slug.current === focusedMenu}
                inverted={inverted}
                sticky={sticky}
                to={`/${menuItem.slug.current}/`}
                onClick={(e) => {
                  if (hasChildItems && isTouchDevice) {
                    e.preventDefault()
                    setFocusedMenu(
                      menuItem.slug.current !== focusedMenu
                        ? menuItem.slug.current
                        : null
                    )
                  } else {
                    closeMenu()
                  }
                }}
              >
                {menuItem.pageTitle[languageCode] || menuItem.pageTitle.en}
                {hasChildItems && <i className="fal fa-angle-down"></i>}
              </MenuLink>
              {hasChildItems && (
                <SubMenu open={menuItem.slug.current === focusedMenu}>
                  <SubMenuItem>
                    <Link
                      underline
                      inverted
                      color={colors.white}
                      to={`/${menuItem.slug.current}/`}
                      onClick={() => closeMenu()}
                    >
                      {t("Show all")}
                    </Link>
                  </SubMenuItem>
                  {menuItem.childCollections.map((subMenuItem, index) => (
                    <SubMenuItem key={index}>
                      <Link
                        underline
                        inverted
                        color={colors.white}
                        to={`/${menuItem.slug.current}/${subMenuItem.slug.current}/`}
                        onClick={() => closeMenu()}
                      >
                        {subMenuItem.pageTitle[languageCode] ||
                          subMenuItem.pageTitle.en}
                      </Link>
                    </SubMenuItem>
                  ))}
                </SubMenu>
              )}
            </MenuItem>
          )
        })}
      </Menu>
      <CountrySelector isMobile />
    </Container>
  )
}
