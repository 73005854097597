import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { useTranslation } from "react-i18next"
import { containerMaxWidth, MEDIA_MIN_LARGE } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import Link from "../Link"
import BottomBar from "./BottomBar"
import NewsletterSignup from "./NewsletterSignup"

const Wrapper = styled.div`
  flex-shrink: 0;
  background-color: ${(p) => p.theme.colors.primary};
`

const Container = styled.div`
  flex-shrink: 0;
  padding: 3rem;
  max-width: ${containerMaxWidth};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  color: ${(p) => p.theme.colors.white};

  ${MEDIA_MIN_LARGE} {
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 8rem 3rem;
  }
`

const Heading = styled.h3`
  width: 100%;
  text-align: center;
  margin-bottom: 2.5rem;
  font-family: "Josefin Sans", "sans-serif";
  font-size: 1.6rem;
  font-weight: 400;
`

const Links = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2.2rem;

  ${MEDIA_MIN_LARGE} {
    margin: 0;
  }
`

const ListItem = styled.li`
  text-align: center;

  a {
    line-height: 1.4rem;
    margin: 1.2rem 0;
    text-transform: uppercase;
    display: inline-block;
  }
`

const Social = styled.div`
  width: 100%;
  margin: 4rem 0 3rem;

  ${MEDIA_MIN_LARGE} {
    margin: 0;
  }
`

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
`

const SocialIcon = styled.a`
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  text-decoration: none;
  margin: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding-top: 0.2rem;
  padding-left: 0.1rem;
  transition: background 0.2s;

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
  }
`

export default ({ footerMenu }) => {
  const { colors } = useContext(ThemeContext)
  const { languageCode } = useContext(BrinkContext)
  const { t } = useTranslation("translations")

  return (
    <Wrapper>
      <Container>
        <Links>
          <Heading>{t("Information")}</Heading>
          <LinkList>
            {footerMenu &&
              footerMenu.map((item) => (
                <ListItem key={item.slug}>
                  <Link
                    underline
                    inverted
                    color={colors.white}
                    to={`/${item.slug}/`}
                  >
                    {item.title[languageCode] || item.title.en}
                  </Link>
                </ListItem>
              ))}
          </LinkList>
        </Links>
        <NewsletterSignup />
        <Social>
          <Heading>{t("Follow us on")}</Heading>
          <SocialIcons>
            <SocialIcon
              href="https://www.instagram.com"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </SocialIcon>
            <SocialIcon
              href="https://www.facebook.com"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </SocialIcon>
            <SocialIcon
              href="https://www.youtube.com"
              title="Youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </SocialIcon>
          </SocialIcons>
        </Social>
      </Container>
      <BottomBar />
    </Wrapper>
  )
}
