import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { PageProvider } from "./context/PageContext"
import { theme } from "../theme"
import Head from "./Head"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import CoockieNotice from "./CoockieNotice"

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`
const Body = styled.div`
  flex: 1 0 auto;
`

export default ({
  meta,
  invertedHeader,
  hideCountrySelector,
  children,
  pageContext
}) => {
  const { headerMenu, footerMenu, sanityTheme } = pageContext

  return (
    <ThemeProvider theme={theme(sanityTheme)}>
      <PageProvider pageContext={pageContext}>
        <Wrapper>
          <Head {...meta} />
          <Header
            meta={meta}
            menuItems={headerMenu}
            inverted={invertedHeader}
            hideCountrySelector={hideCountrySelector}
          />
          <CoockieNotice />
          <Body>{children}</Body>
          <Footer footerMenu={footerMenu} />
        </Wrapper>
      </PageProvider>
    </ThemeProvider>
  )
}
