import countries from "i18n-iso-countries"
countries.registerLocale(require("i18n-iso-countries/langs/en.json"))
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"))
countries.registerLocale(require("i18n-iso-countries/langs/nl.json"))
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))
countries.registerLocale(require("i18n-iso-countries/langs/fi.json"))
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"))
countries.registerLocale(require("i18n-iso-countries/langs/no.json"))
countries.registerLocale(require("i18n-iso-countries/langs/da.json"))

export const getLocalizedCountryName = (countryCode, languageCode) =>
  countries.getName(countryCode, languageCode)
