import React, { useState, useContext } from "react"
import styled from "styled-components"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_LARGE, MEDIA_MIN_X_LARGE } from "../../constants"
import Search from "./Search"
import BagIcon from "../../icons/bag-icon.svg"
import CountdownBanner from "../widgets/CountdownBanner"

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 3rem;
  color: ${(p) =>
    p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};

  ${MEDIA_MIN_LARGE} {
    position: relative;
    min-width: 13rem;
    justify-content: flex-end;
  }

  ${MEDIA_MIN_X_LARGE} {
    min-width: 16rem;
    right: 0;
  }
`

const SearchIcon = styled.div`
  margin: 0 2.4rem;
  cursor: pointer;

  i {
    font-size: 2rem;
  }
`

const CartIcon = styled.div`
  margin-left: 2.4rem;
  cursor: pointer;
  position: relative;

  svg {
    width: 2rem;
    margin-bottom: 0.2rem;
    fill: ${(p) =>
      p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};
  }

  .st0 {
    fill: transparent;
  }
`

const CartQuantity = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.1rem;
  padding: 0.1rem 0 0 0.1rem;
  color: ${(p) =>
    p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};
`

export default ({ toggleCart, inverted, sticky }) => {
  const [openSearch, setOpenSearch] = useState(false)
  const { cart, isCartClosed } = useContext(BrinkContext)
  const cartQuantity = !isCartClosed()
    ? cart.cartItems.reduce((prev, cur) => {
        if (cur.type === "shippingOption") return prev
        return prev + cur.quantity
      }, 0)
    : 0

  return (
    <Container inverted={inverted} sticky={sticky}>
      <SearchIcon onClick={() => setOpenSearch(!openSearch)}>
        <i className="fal fa-search"></i>
      </SearchIcon>
      {isCartClosed && <CountdownBanner inverted={inverted} inHeader />}
      <CartIcon
        inverted={inverted}
        sticky={sticky}
        onClick={() => toggleCart()}
      >
        <BagIcon />
        <CartQuantity inverted={inverted} sticky={sticky}>
          {cartQuantity}
        </CartQuantity>
      </CartIcon>
      <Search
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        sticky={sticky}
      />
    </Container>
  )
}
