import React, { useState, useEffect, useContext } from "react"
import { useCookies } from "react-cookie"
import styled, { ThemeContext } from "styled-components"
import { boxShadow, MEDIA_MIN_MEDIUM } from "../constants"
import Link from "../components/Link"

const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  color: white;
  background: ${(p) => p.theme.colors.primary};
  max-width: 50rem;
  padding: 1rem 5rem 1rem 3rem;
  box-shadow: ${boxShadow};
  transition: opacity: 0.3s;
  z-index: ${(p) => (p.visible ? "1000" : "-1")};
  opacity: ${(p) => (p.visible ? "1" : "0")};
  font-size: 1.4rem;

  ${MEDIA_MIN_MEDIUM} {
    left: auto;
  }

  a {
    margin-left: 0.5rem;
    white-space: nowrap;
  }
`

const Close = styled.div`
  position: absolute;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
`

export default () => {
  const { colors } = useContext(ThemeContext)
  const [cookie, setCookie] = useCookies("notice")
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!cookie.notice) setVisible(true)
  }, [cookie])

  return (
    <Container visible={visible}>
      <Close
        onClick={() => {
          setCookie("notice", 1, { path: "/" })
          setVisible(false)
        }}
      >
        <i className="fal fa-times"></i>
      </Close>
      <p>
        We use cookies to give you the best experience on our site.
        <Link underline color={colors.white} to="/terms-and-conditions/">
          Read more
        </Link>
      </p>
    </Container>
  )
}
