/* eslint jsx-a11y/html-has-lang: 0 */
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { BrinkContext, NotificationTypes } from "../context/BrinkContext"
import { boxShadow } from "../../constants"
import Button from "../ui/Button"
import BagIcon from "../../icons/bag-icon.svg"
import Item from "./Item"
import Totals from "./Totals"
import CountdownBanner from "../widgets/CountdownBanner"
import Loader from "../ui/Loader"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 50rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: white;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.black};
  box-shadow: ${boxShadow};
  transform: translate(${(p) => (p.openCart ? "0, 0" : "100%, 0")});
  transition: transform 0.2s;
  z-index: 10001;
`
const Header = styled.div`
  position: relative;
  width: 100%;
  height: 4rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 3rem 3rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};

  div {
    display: flex;
    align-items: center;

    svg {
      width: 2rem;
      height: 2.4rem;
      margin: 0 1rem 0.8rem 0;
    }

    .st0 {
      fill: transparent;
    }
  }
`

const EmptyCart = styled.p`
  text-align: center;
  margin-top: 3rem;
`

const Heading = styled.h3`
  font-size: 1.8rem;
  font-family: "Josefin Sans", "sans-serif";
  display: flex;
`

const CartQuantity = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  padding: 0 0 0.2rem 0;
  position: absolute;
  width: 2rem;
`

const CloseButton = styled.div`
  position: absolute;
  top: 3rem;
  right: 3rem;
  font-size: 2rem;
  cursor: pointer;
`

const Body = styled.div`
  width: 100%;
  height: calc(100% - 12rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 3rem 0 3rem;
  margin-bottom: -0.1rem;
`

const Footer = styled.div`
  background: ${(p) => p.theme.colors.white};
  padding: 0 3rem 3rem;
`

const CheckoutButton = styled(Button)`
  width: 100%;
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
`

const Alert = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  border: none;
  border-radius: 0.3rem;
  padding: 1.7rem 3rem;
  position: fixed;
  bottom: 7rem;
  right: 2rem;
  z-index: 10002;
  transition: transform 0.4s;
  transform: translate(${(p) => (p.showMessage ? "0, 0" : "calc(100% + 3rem), 0")});

  i {
    font-size: 2.4rem;
    margin-right: 0.8rem;
  }
`

const Error = styled(Alert)`
  background: ${(p) => p.theme.colors.errors};
  color: white;
`

export default ({ closeCart, openCart }) => {
  const { t } = useTranslation("translations")
  const { cart, isLoading, isCartClosed, notification, clearNotification } = useContext(BrinkContext)
  const [alert, setAlert] = useState(null)
  const cartQuantity = !isCartClosed()
    ? cart.cartItems.reduce((prev, cur) => {
        if (cur.type === "shippingOption") return prev
        return prev + cur.quantity
      }, 0)
    : 0

  useEffect(() => {
    if (notification.CART.event === "UPDATE_CART" && notification.CART.severity === "ERROR") {
      setAlert(notification.CART)
      setTimeout(() => {
        clearNotification(NotificationTypes.CART)
        setAlert(null)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  return (
    <>
      <Error showMessage={alert !== null}>
        <i className="fal fa-exclamation-circle"></i>
        {notification.CART.message}
      </Error>
      <Container openCart={openCart}>
        {openCart && (
          <Helmet>
            <html className="disableScroll" />
            <body className="disableScroll" />
          </Helmet>
        )}
        <Header>
          <div>
            <BagIcon />
            <Heading>{t("Your cart")}</Heading>
            <CartQuantity>{cartQuantity}</CartQuantity>
          </div>
          <CloseButton onClick={() => closeCart()}>
            <i className="fal fa-times"></i>
          </CloseButton>
        </Header>
        {openCart && <CountdownBanner inCart cartQuantity={cartQuantity} cartLimit={cart?.reservationQuantityLimit} />}
        <Body>
          {cartQuantity !== 0 && !isCartClosed() ? (
            <>
              <Loader isLoading={isLoading} />
              <Items>
                {cart.cartItems.map((item) => {
                  return <Item item={item} key={item.rowId} showControls={true} />
                })}
              </Items>
              <Footer>
                <Totals hideShipping={true} />
                <Link to="/checkout/">
                  <CheckoutButton onClick={() => closeCart()}>{t("Proceed to checkout")}</CheckoutButton>
                </Link>
              </Footer>
            </>
          ) : (
            <EmptyCart>{t("You have no items in your cart.")}</EmptyCart>
          )}
        </Body>
      </Container>
    </>
  )
}
