import React from "react"
import styled from "styled-components"
import { containerSmallMaxWidth } from "../../constants"
import Usp from "../widgets/Usp"
import CountrySelector from "./CountrySelector"

const Container = styled.div`
  width: 100%;
  text-align: center;
  color: white;
  position: relative;
  font-size: 1.2rem;
  color: ${(p) => (p.inverted ? p.theme.colors.black : p.theme.colors.white)};
`

const Grid = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto;
  position: relative;
  height: 4.5rem;
  padding-top: 0.2rem;
  border-bottom: 1px solid
    ${(p) =>
      p.inverted ? p.theme.colors.primaryLight : "rgba(255, 255, 255, 0.2)"};
`

export default ({ inverted, hideCountrySelector }) => (
  <Container inverted={inverted}>
    <Grid inverted={inverted}>
      <Usp inverted={inverted} />
      {!hideCountrySelector && <CountrySelector inverted={inverted} />}
    </Grid>
  </Container>
)
