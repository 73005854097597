import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_LARGE } from "../../constants"

const MenuButton = styled.div`
  width: 2.4rem;
  height: 1.8rem;
  position: absolute;
  left: 3rem;

  div {
    background: ${(p) =>
      p.inverted || p.sticky ? p.theme.colors.black : p.theme.colors.white};
  }

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const Bar = styled.div`
  height: 0.2rem;
  width: 100%;
  position: absolute;
`

const Top = styled(Bar)`
  top: 0;
`

const Middle = styled(Bar)`
  top: 50%;
  margin-top: -0.1rem;
`

const Bottom = styled(Bar)`
  bottom: 0;
`

export default ({ toggleMenu, openMenu, sticky, inverted }) => {
  return (
    <MenuButton
      sticky={sticky}
      inverted={inverted}
      onClick={() => toggleMenu(!openMenu)}
    >
      <Top />
      <Middle />
      <Bottom />
    </MenuButton>
  )
}
