import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { useForm, Controller } from "react-hook-form"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Input from "../ui/Input"
import Button from "../ui/Button"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;

  label {
    position: absolute;
    height: 0.1rem;
    width: 0.1rem;
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
    border: 0;
    overflow: hidden;
  }

  p {
    text-align: center;
    margin: 1rem 0 2rem;

    ${MEDIA_MIN_MEDIUM} {
      margin: 3rem 0;
    }
  }
`

const Heading = styled.h3`
  width: 100%;
  text-align: center;
  margin-bottom: 2.5rem;
  font-family: "Josefin Sans", "sans-serif";
  font-size: 1.6rem;
  font-weight: 400;
`

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
  }
`

const EmailField = styled(Input)`
  width: 100%;
  margin-bottom: 1rem;

  input {
    border: 0.1rem solid ${(p) => p.theme.colors.white};
    background-color: transparent;
    color: ${(p) => p.theme.colors.white};
    text-transform: initial;
  }

  ${MEDIA_MIN_MEDIUM} {
    width: 70%;
    margin-bottom: 0;
  }
`

const SubmitButton = styled(Button)`
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: calc(30% - 1rem);
    margin-left: 1rem;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  padding: 2rem 0;
  line-height: 2rem;
  max-width: 40rem;
  margin: 0 auto;
  letter-spacing: 0.05rem;

  i {
    font-size: 2rem;
    margin-right: 0.8rem;
  }

  a {
    display: none;
  }
`

export default () => {
  const { t } = useTranslation("translations")
  const { setIsLoading } = useContext(BrinkContext)
  const { control, errors, handleSubmit } = useForm()
  const [mailchimpResponse, setMailchimpResponse] = useState({
    __html: null,
    type: null
  })
  const onSubmit = async (values) => {
    setIsLoading(true)
    const response = await addToMailchimp(values.email)
    setMailchimpResponse({ __html: response.msg, type: response.result })
    setIsLoading(false)
  }

  useEffect(() => {
    setMailchimpResponse({ __html: null, type: null })
  }, [errors.email])

  return (
    <Container>
      <div>
        <Heading>{t("Receive our newsletter")}</Heading>
        <p>
          {t(
            "Enter your e-mail address below to get our newsletter and offers."
          )}
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={EmailField}
            placeholder={t("Your e-mail")}
            label={t("Your e-mail")}
            control={control}
            name="email"
            type="email"
            rules={{
              required: t("This is a required field"),
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t("Please supply a valid e-mail address")
              }
            }}
          />

          <SubmitButton>{t("Submit")}</SubmitButton>
        </Form>
        <MessageContainer>
          {Object.keys(errors).length !== 0 ? (
            <>
              <i className="fal fa-exclamation-circle"></i>
              {errors.email.message}
            </>
          ) : (
            mailchimpResponse.__html && (
              <>
                {mailchimpResponse.type === "success" ? (
                  <i className="fal fa-check-circle"></i>
                ) : (
                  <i className="fal fa-exclamation-circle"></i>
                )}
                <span dangerouslySetInnerHTML={mailchimpResponse} />
              </>
            )
          )}
        </MessageContainer>
      </div>
    </Container>
  )
}
