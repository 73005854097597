import React, { useContext, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { v4 as uuidv4 } from "uuid"
import Input from "../ui/Input"
import Button from "../ui/Button"

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.primaryOpac};
  height: 9rem;
  padding: 0 2rem;
  top: ${(p) => (p.sticky ? "7rem" : "14.2rem")};
  right: 0;
  left: 0;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.openSearch ? "1" : "0")};
  z-index: ${(p) => (p.openSearch ? "10000" : "-1")};
  pointer-events: ${(p) => (p.openSearch ? "all" : "none")};

  label {
    position: absolute;
    height: 0.1rem;
    width: 0.1rem;
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
    border: 0;
    overflow: hidden;
  }

  &:after {
    bottom: 100%;
    right: 7.5rem;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: ${(p) => p.theme.colors.primary};
    border-width: 0.8rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    top: 100%;
    position: absolute;
    width: 40rem;
    right: 0;
    left: auto;

    &:after {
      right: 4.4rem;
    }
  }
`

const SearchInput = styled(Input)`
  input {
    background: transparent;
    border: 0.1rem solid ${(p) => p.theme.colors.white};
    border-right: none;
    color: ${(p) => p.theme.colors.white};
    text-transform: initial;
  }
`

const SearchButton = styled(Button)`
  background: transparent;
  border: 0.1rem solid ${(p) => p.theme.colors.white};
  border-left: none;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 2rem;
    color: ${(p) => p.theme.colors.white};
  }
`

export default ({ openSearch, setOpenSearch, sticky }) => {
  const { t } = useTranslation("translations")
  const { setSearchQuery, setIsLoading, setSearchId } = useContext(BrinkContext)
  const [searchQuery, _setSearchQuery] = useState()

  const updateQuery = (c) => {
    _setSearchQuery(c.target.value)
  }

  const setQuery = () => {
    if (!searchQuery) return false
    setSearchQuery(searchQuery)
    setIsLoading(true)
    setOpenSearch(false)
    setSearchId(uuidv4())
    navigate("/search")
  }

  return (
    <Container openSearch={openSearch} sticky={sticky}>
      <SearchInput
        label={t("Search products")}
        id="searchInput"
        name="searchInput"
        placeholder={`${t("Search")}...`}
        onChange={(c) => updateQuery(c)}
        onKeyDown={(e) => e.keyCode === 13 && setQuery()}
      />
      <SearchButton
        aria-label={t("Search products")}
        onClick={() => setQuery()}
      >
        <i className="fal fa-search"></i>
      </SearchButton>
    </Container>
  )
}
