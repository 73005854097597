import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { usePage } from "../context/PageContext"

const Image = styled(Img)`
  width: 2rem;
  margin-right: 1rem;
  margin-bottom: 0.2rem;
`

const FlagIcon = ({ countryCode, white, inverted }) => {
  const { flags } = usePage()
  const [flag, setFlag] = useState(null)
  const currentCountryCode = countryCode.toLowerCase()

  let currentFlag

  if (currentCountryCode === "us" && !inverted && white) {
    currentFlag = flags.find(
      (flag) => flag.name === `${currentCountryCode}-white`
    )
  } else {
    currentFlag = flags.find((flag) => flag.name === currentCountryCode)
  }

  useEffect(() => {
    setFlag(currentFlag)
  }, [currentFlag])

  if (!flag) return null

  return (
    <Image
      fluid={flag.childImageSharp.fluid}
      loading="eager"
      width="20"
      height="20"
    />
  )
}

export default FlagIcon
