import React, { useState, useContext } from "react"
import styled, { css } from "styled-components"
import i18next from "i18next"
import ISO6391 from "iso-639-1"
import { getLocalizedCountryName } from "../../helpers/countries"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_LARGE, MEDIA_MIN_X_LARGE } from "../../constants"
import FlagIcon from "../ui/FlagIcon"
import OutsideClickHandler from "react-outside-click-handler"

const Container = styled.div`
  display: ${(p) => (p.isMobile ? "flex" : "none")};
  cursor: pointer;

  ${MEDIA_MIN_LARGE} {
    display: ${(p) => (p.isMobile ? "none" : "flex")};
    align-items: center;
    position: absolute;
    right: 2.5rem;
    top: 1rem;
  }

  ${MEDIA_MIN_X_LARGE} {
    right: 0;
  }

  ${(p) =>
    p.isMobile &&
    css`
      > div {
        display: flex;
        width: 100%;
      }
    `}

  i {
    font-size: 1.4rem;
    margin-left: 0.4rem;
    padding-top: 0.4rem;
  }
`

const SelectorWrapper = styled.div`
  position: ${(p) => (p.isMobile ? "relative" : "absolute")};
  top: ${(p) => (p.isMobile ? "0" : "calc(100% + 0.1rem)")};
  background: ${(p) => (p.isMobile ? p.theme.colors.primary : p.theme.colors.primaryOpac)};
  padding: ${(p) => (p.isMobile ? "2rem 3rem 1rem" : "2rem 2rem 1rem")};
  width: ${(p) => (p.isMobile ? "100%" : "35rem")};
  right: 0;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.show ? "1" : "0")};
  z-index: ${(p) => (p.show ? "10000" : "-1")};
  pointer-events: ${(p) => (p.show ? "all" : "none")};
  box-shadow: ${(p) => (p.isMobile ? "none" : "0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.1)")};

  &:after {
    bottom: 100%;
    right: 9rem;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: ${(p) => (p.isMobile ? p.theme.colors.primary : p.theme.colors.primaryOpac)};
    border-width: 0.8rem;
  }
`

const Selector = styled.div`
  position: relative;
  padding-right: 2rem;
  height: 5rem;
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.black};
  margin: 1rem 0;
  display: flex;
  align-items: center;
  padding: 0 2rem 0 2rem;
  justify-content: space-between;
  cursor: pointer;
  text-transform: capitalize;
`

const Selected = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  font-size: 1.2rem;
  text-transform: capitalize;
`

const Label = styled.div`
  color: ${(p) => p.theme.colors.black};
  text-align: left;
  font-size: 1.2rem;
`

const Options = styled.ul`
  position: absolute;
  top: calc(100% + 0.1rem);
  left: 0;
  right: 0;
  background: ${(p) => p.theme.colors.white};
  padding: 0;
  list-style: none;
  margin: 0 0 2rem;
  z-index: 1;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.show ? "1" : "0")};
  z-index: ${(p) => (p.show ? "10000" : "-1")};
  pointer-events: ${(p) => (p.show ? "all" : "none")};
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow-y: scroll;
`

const Option = styled.li`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2rem 1rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.primaryLight};
  transition: background 0.2s;
  cursor: pointer;
  font-size: 1.2rem;

  div {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: ${(p) => p.theme.colors.primaryLight};
    border-bottom: 0.1rem solid transparent;
  }
`

const CountrySelector = ({ isMobile, showAsPopup }) => {
  const { t } = useTranslation("translations")
  const { languageCode, closeCart, supportedLanguages, stores, currentStore, setCurrentStore } =
    useContext(BrinkContext)

  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const [showCurrencyOptions, setShowCurrencyOptions] = useState(false)
  const [showLanguageOptions, setShowLanguageOptions] = useState(false)

  const closeAll = () => {
    setShowCountrySelector(false)
    setShowCurrencyOptions(false)
    setShowLanguageOptions(false)
  }

  const outsideClose = () => {
    if (showLanguageOptions) {
      setShowLanguageOptions(false)
    } else if (showCurrencyOptions) {
      setShowCurrencyOptions(false)
    } else {
      closeAll()
    }
  }

  if (!currentStore) return null
  return (
    <Container isMobile={isMobile} showAsPopup={showAsPopup}>
      {!isMobile && (
        <Selected onClick={() => setShowCountrySelector(!showCountrySelector)}>
          <FlagIcon countryCode={currentStore.countryCode} />
          {currentStore.currencyUnit} | {ISO6391.getNativeName(languageCode)}
          <i className="fal fa-angle-down"></i>
        </Selected>
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          outsideClose()
        }}
      >
        <SelectorWrapper isMobile={isMobile} show={showCountrySelector || isMobile}>
          <Label>{t("Country")}</Label>
          <Selector
            onClick={() => {
              setShowLanguageOptions(false)
              setShowCurrencyOptions(!showCurrencyOptions)
            }}
          >
            <Selected>
              <FlagIcon countryCode={currentStore.countryCode} />{" "}
              {getLocalizedCountryName(currentStore.countryCode, languageCode, {
                select: "official"
              })}{" "}
              | {currentStore.currencyUnit}
            </Selected>
            <i className="fal fa-angle-down"></i>
            <Options show={showCurrencyOptions}>
              {stores
                .sort((a, b) =>
                  getLocalizedCountryName(a.countryCode, languageCode).localeCompare(
                    getLocalizedCountryName(b.countryCode, languageCode)
                  )
                )
                .map((store) => (
                  <Option
                    key={store.countryCode}
                    selected={store.countryCode === currentStore.countryCode}
                    onClick={() => {
                      setCurrentStore(store)
                      closeCart()
                      closeAll()
                    }}
                  >
                    <div>
                      <FlagIcon countryCode={store.countryCode} />
                      {getLocalizedCountryName(store.countryCode, languageCode)} | {store.currencyUnit}
                    </div>
                    {store.countryCode === currentStore.countryCode && <i className="fal fa-check"></i>}
                  </Option>
                ))}
            </Options>
          </Selector>
          <Label>{t("Language")}</Label>
          <Selector
            onClick={() => {
              setShowCurrencyOptions(false)
              setShowLanguageOptions(!showLanguageOptions)
            }}
          >
            <Selected>{ISO6391.getNativeName(languageCode)}</Selected>
            <i className="fal fa-angle-down"></i>
            <Options show={showLanguageOptions}>
              {supportedLanguages.map((language) => (
                <Option
                  key={language}
                  selected={language === languageCode}
                  onClick={() => {
                    closeAll()
                    i18next.changeLanguage(language)
                  }}
                >
                  {ISO6391.getNativeName(language)}
                  {language === languageCode && <i className="fal fa-check"></i>}
                </Option>
              ))}
            </Options>
          </Selector>
        </SelectorWrapper>
      </OutsideClickHandler>
    </Container>
  )
}

export default CountrySelector
