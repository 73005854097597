import React, { useState, useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import {
  containerSmallMaxWidth,
  MEDIA_MIN_LARGE,
  MEDIA_MIN_X_LARGE
} from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import Link from "../Link"
import MenuButton from "./MenuButton"
import Menu from "./Menu"
import Tools from "./Tools"
import TopBar from "./TopBar"
import Logo from "../../images/brink-logo.svg"
import Cart from "../cart/Cart"

const Wrapper = styled.div`
  width: 100%;
  position: ${(p) => (p.sticky ? "fixed" : "absolute")};
  z-index: 10;
  background: ${(p) => (p.sticky ? "rgba(255,255,255,0.95)" : "transparent")};
  box-shadow: ${(p) => (p.sticky ? "0 1px 4px 0 rgba(0, 0, 0, 0.1)" : "none")};
`

const Container = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(p) => (p.sticky ? "7rem" : "10rem")};
  border-bottom: 0.1rem solid
    ${(p) => (p.inverted ? p.theme.colors.primaryLight : "none")};

  ${MEDIA_MIN_X_LARGE} {
    border-bottom: 0.1rem solid
      ${(p) =>
        p.inverted && !p.sticky
          ? p.theme.colors.primaryLight
          : "rgba(255, 255, 255, 0.2)"};
  }
`

const HomeLink = styled(Link)`
  display: block;
  margin: 0 auto;

  ${MEDIA_MIN_LARGE} {
    display: inline-block;
    padding-left: 3rem;
  }

  ${MEDIA_MIN_X_LARGE} {
    display: inline-block;
    padding-left: 0;
  }

  svg {
    width: 10rem;
    padding-top: 0.2rem;
    fill: ${(p) =>
      p.inverted || p.sticky ? p.theme.colors.secondary : p.theme.colors.white};

    ${MEDIA_MIN_X_LARGE} {
      width: 11rem;
    }
  }
`

const Overlay = styled.div`
  opacity: ${(p) => (p.show ? 1 : 0)};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: ${(p) => (p.show ? 10000 : -1)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({ menuItems = [], inverted, hideCountrySelector }) => {
  const { isCartOpen, setIsCartOpen } = useContext(BrinkContext)
  const [openMenu, setOpenMenu] = useState(false)
  const [sticky, setSticky] = useState(false)
  const headerRef = useRef()

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen)
  }

  const close = () => {
    setIsCartOpen(false)
    setOpenMenu(false)
  }

  const stick = () => {
    if (window.pageYOffset >= 42) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", stick)
    }
    watchScroll()
    return () => {
      window.removeEventListener("scroll", stick)
    }
  })

  return (
    <div className="top">
      <Wrapper ref={headerRef} sticky={sticky}>
        {!sticky && (
          <TopBar
            inverted={inverted}
            hideCountrySelector={hideCountrySelector}
          />
        )}
        <Container inverted={inverted} sticky={sticky}>
          <MenuButton
            sticky={sticky}
            inverted={inverted}
            toggleMenu={toggleMenu}
            openMenu={openMenu}
          />
          <HomeLink
            title="Go to start page"
            inverted={inverted}
            sticky={sticky}
            to="/"
          >
            <Logo alt="Brink Demo shop logo" />
          </HomeLink>
          <Menu
            closeMenu={close}
            openMenu={openMenu}
            menuItems={menuItems}
            inverted={inverted}
            sticky={sticky}
          />
          <Tools inverted={inverted} sticky={sticky} toggleCart={toggleCart} />
        </Container>
      </Wrapper>
      <Overlay show={isCartOpen} onClick={() => close()} />
      <Cart closeCart={close} openCart={isCartOpen} />
    </div>
  )
}
